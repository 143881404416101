import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {http_auth} from "../../utility/Utils"
import {toast} from "react-toastify"


export const getDividendsList = createAsyncThunk('dividends/getDividendsList', async (data, {rejectWithValue}) => {
    try {
        const response = await http_auth.get('/dividends', {
            params: data
        })
        return response.data
    } catch (error) {
        return rejectWithValue(error.message)
    }
})

export const createDividend = createAsyncThunk('dividends/createDividend', async (data, {rejectWithValue}) => {
    try {
        const response = await http_auth.post('/dividends', data)
        return response.data
    } catch (error) {
        return rejectWithValue(error.message)
    }
})

export const createListDividends = createAsyncThunk('dividends/createListDividends', async (data, {rejectWithValue}) => {
    try {
        const response = await http_auth.post('/dividends/all', data)
        return response.data
    } catch (error) {
        return rejectWithValue(error.message)
    }
})

export const deleteDividend = createAsyncThunk('dividends/deleteDividend', async (id, {rejectWithValue}) => {
    try {
        const response = await http_auth.delete(`/dividends/${id}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.message)
    }
})

export const patchDividend = createAsyncThunk('dividends/patchDividend', async (data, {rejectWithValue}) => {
    try {
        const response = await http_auth.patch(`/dividends/${data?.id}`, data?.body)
        return response.data
    } catch (error) {
        return rejectWithValue(error.message)
    }
})

export const dividendSlice = createSlice({
    name: 'dividend',
    initialState: {
        dividends: [],
        dividend: null,

        isLoading: false,
        currentPage: 0,
        pageCount: 0,
        limit: 10,
        totalCount: 0
    },
    reducers: {
        setIssuer: (state, action) => {
            state.dividend = action.payload
        }
    },
    extraReducers: {
        [getDividendsList.fulfilled]: (state, action) => {
            state.dividends = action.payload?.data
            state.currentPage = action.payload?.currentPage
            state.limit = action.payload?.limit
            state.pageCount = action.payload?.pageCount
            state.totalCount = action.payload?.totalCount
            state.isLoading = false
        },
        [getDividendsList.pending]: (state) => {
            state.isLoading = true
        },
        [getDividendsList.rejected]: (state, action) => {
            toast.error(action.payload)
            state.isLoading = false
        },

        [createDividend.fulfilled]: (state, action) => {
            state.dividends.push(action.payload.data)
            state.isLoading = false
        },
        [createDividend.pending]: (state) => {
            state.isLoading = true
        },
        [createDividend.rejected]: (state, action) => {
            toast.error(action.payload)
            state.isLoading = false
        },


        [createListDividends.fulfilled]: (state, action) => {
            state.dividends.concat(action.payload.data)
            state.isLoading = false
        },
        [createListDividends.pending]: (state) => {
            state.isLoading = true
        },
        [createListDividends.rejected]: (state, action) => {
            toast.error(action.payload)
            state.isLoading = false
        },

        [deleteDividend.fulfilled]: (state, action) => {
            state.dividends = state.dividends.filter(item => item.id !== action?.meta?.arg)
            state.isLoading = false
        },
        [deleteDividend.pending]: (state) => {
            state.isLoading = true
        },
        [deleteDividend.rejected]: (state, action) => {
            toast.error(action.payload)
            state.isLoading = false
        },

        [patchDividend.fulfilled]: (state, action) => {
            state.dividends[state.dividends.findIndex(item => item.id === action.payload?.data?.id)] = action.payload?.data
        },
        [patchDividend.rejected]: (state, action) => {
            toast.error(action.payload)
        }
    }
})

export const {setIssuer} = dividendSlice.actions

export default dividendSlice.reducer