// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './reducers/auth'
import users from './reducers/user'
import contents from './reducers/contents'
import issuers from './reducers/issuers'
import dividends from './reducers/dividends'

const rootReducer = {
  auth,
  navbar,
  layout,
  users,
  issuers,
  contents,
  dividends
}

export default rootReducer
