import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {http_auth} from "../../utility/Utils"
import {toast} from "react-toastify"

export const uploadFile = createAsyncThunk('contents/uploadFile', async (data, {rejectWithValue}) => {
    try {
        const response = await http_auth.post(`/upload`, data)
        if (response.data === null) return rejectWithValue(response?.data)
        return response.data

    } catch (error) {
        return rejectWithValue(error)
    }
})

// ================================= NEWS PAPER ========================================================================
export const createNewsPaper = createAsyncThunk('contents/createNewsPaper', async (data, {rejectWithValue}) => {
    try {
        const response = await http_auth.post(`/news`, data)
        if (response.data === null) return rejectWithValue(response?.data)
        return response.data

    } catch (error) {
        return rejectWithValue(error)
    }
})

export const getNewsPaperList = createAsyncThunk('contents/getNewsPaperList', async (data, {rejectWithValue}) => {
    try {
        const response = await http_auth.get(`/news`, {
            params: data
        })
        if (response.data === null) return rejectWithValue(response?.data)
        return response.data

    } catch (error) {
        return rejectWithValue(error)
    }
})

export const deleteNewsPaper = createAsyncThunk('contents/deleteNewsPaper', async (id, {rejectWithValue}) => {
    try {
        const response = await http_auth.delete(`/news/${id}`)
        if (response.data === null) return rejectWithValue(response?.data)
        return response.data

    } catch (error) {
        return rejectWithValue(error)
    }
})


// ============================= TRANSLATE =============================================================================
export const getTranslateList = createAsyncThunk('contents/getTranslateList', async (data, {rejectWithValue}) => {
    try {
        const response = await http_auth.get(`/langs`, {
            params: data
        })
        if (response.data === null) return rejectWithValue(response?.data)
        return response.data

    } catch (error) {
        return rejectWithValue(error)
    }
})

export const deleteTranslate = createAsyncThunk('contents/deleteTranslate', async (id, {rejectWithValue}) => {
    try {
        const response = await http_auth.delete(`/langs/${id}`)
        if (response.data === null) return rejectWithValue(response?.data)
        return response.data

    } catch (error) {
        return rejectWithValue(error)
    }
})

export const createTranslate = createAsyncThunk('contents/createTranslate', async (data, {rejectWithValue}) => {
    try {
        const response = await http_auth.post(`/langs`, data)
        if (response.data === null) return rejectWithValue(response?.data)
        return response.data

    } catch (error) {
        return rejectWithValue(error)
    }
})

export const patchTranslate = createAsyncThunk('contents/patchTranslate', async (data, {rejectWithValue}) => {
    try {
        const response = await http_auth.patch(`/langs/${data.id}`, data.body)
        if (response.data === null) return rejectWithValue(response?.data)
        return response.data

    } catch (error) {
        return rejectWithValue(error)
    }
})

// =====================================================================================================================

const initialState = {
    fileUrl: null,
    fileName: '',
    translations: [],

    isLoading: false,
    currentPage: 0,
    pageCount: 0,
    limit: 10,
    totalCount: 0
}

const reducers = {
    removeItem: (state, action) => {
        state.news = state.news.filter(item => item.id !== action.payload.id)
    }
}

export const contentsSlice = createSlice({
    name: 'contents',
    initialState,
    reducers,
    extraReducers: {
        [uploadFile.fulfilled]: (state, action) => {
            state.fileUrl = action.payload.url
            state.fileName = action.payload.filename
            state.isLoading = false
        },
        [uploadFile.pending]: (state) => {
            state.isLoading = true
        },
        [uploadFile.rejected]: (state) => {
            state.isLoading = false
        },
        // ======== ========== NEWS PAPER =================== =================== ================
        [createNewsPaper.fulfilled]: (state, action) => {
            state.news.push(action.payload.data)
            state.isLoading = false
        },
        [createNewsPaper.pending]: (state) => {
            state.isLoading = true
        },
        [createNewsPaper.rejected]: (state) => {
            state.isLoading = false
        },
        [getNewsPaperList.fulfilled]: (state, action) => {
            state.news = action.payload.data
            state.limit = action.payload.results
            state.totalCount = action.payload.count
            state.isLoading = false
        },
        [getNewsPaperList.pending]: (state) => {
            state.isLoading = true
        },
        [getNewsPaperList.rejected]: (state) => {
            state.isLoading = false
        },
        [deleteNewsPaper.fulfilled]: (state, action) => {
            state.news = state.news.filter(item => item?.id !== action.meta.arg)
            state.isLoading = false
        },
        [deleteNewsPaper.pending]: (state) => {
            state.isLoading = true
        },
        [deleteNewsPaper.rejected]: (state) => {
            state.isLoading = false
        },

        // ======== ========== TRANSLATE =================== =================== ================

        [getTranslateList.fulfilled]: (state, action) => {
            state.translations = action.payload?.data
            state.currentPage = action.payload?.currentPage
            state.limit = action.payload?.limit
            state.pageCount = action.payload?.pageCount
            state.totalCount = action.payload?.totalCount
            state.isLoading = false
        },
        [getTranslateList.pending]: (state) => {
            state.isLoading = true
        },
        [getTranslateList.rejected]: (state) => {
            state.isLoading = false
        },
        [deleteTranslate.fulfilled]: (state, action) => {
            state.translations = state.translations.filter(item => item?.id !== action.meta.arg)
            state.isLoading = false
        },
        [deleteTranslate.pending]: (state) => {
            state.isLoading = true
        },
        [deleteTranslate.rejected]: (state) => {
            state.isLoading = false
        },
        [createTranslate.fulfilled]: (state, action) => {
            state.translations.push(action.payload.data)
            state.isLoading = false
        },
        [createTranslate.pending]: (state) => {
            state.isLoading = true
        },
        [createTranslate.rejected]: (state) => {
            state.isLoading = false
        },
        [patchTranslate.fulfilled]: (state, action) => {
            state.translations[state.translations.findIndex(item => item.id === action.payload?.data?.id)] = action.payload?.data
            state.isLoading = false
        },
        [patchTranslate.pending]: (state) => {
            state.isLoading = true
        },
        [patchTranslate.rejected]: (state, action) => {
            toast.error(action.payload)
            state.isLoading = false
        }
    }
})

export const {removeItem} = contentsSlice.actions
export default contentsSlice.reducer