import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {http_auth} from "../../utility/Utils"
import {toast} from "react-toastify"
import i18n from "i18next"


export const getIssuers = createAsyncThunk('app/getIssuers', async (data, {rejectWithValue}) => {
    try {
        const response = await http_auth.get('/issuers', {
            params: data
        })
        return response.data
    } catch (error) {
        return rejectWithValue(error.message)
    }
})

export const createIssuers = createAsyncThunk('app/createIssuers', async (data, {rejectWithValue}) => {
    try {
        const response = await http_auth.post('/issuers', data)
        return response.data
    } catch (error) {
        return rejectWithValue(error.message)
    }
})

export const deleteIssuers = createAsyncThunk('app/deleteIssuers', async (id, {rejectWithValue}) => {
    try {
        const response = await http_auth.delete(`/issuers/${id}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.message)
    }
})

export const patchIssuers = createAsyncThunk('app/patchIssuers', async (data, {rejectWithValue}) => {
    try {
        const response = await http_auth.patch(`/issuers/${data?.id}`, data?.body)
        return response.data
    } catch (error) {
        return rejectWithValue(error.message)
    }
})

export const fetchIndexesFin = createAsyncThunk('app/fetchIndexesFin', async (_, {rejectWithValue}) => {
    try {
        const response = await http_auth.patch(`/indexes/fin`, {})
        return response.data
    } catch (error) {
        return rejectWithValue(error.message)
    }
})

export const issuerSlice = createSlice({
    name: 'issuer',
    initialState: {
        issuers: [],
        issuer: null,

        isLoading: false,
        currentPage: 0,
        pageCount: 0,
        limit: 10,
        totalCount: 0
    },
    reducers: {
        setIssuer: (state, action) => {
            state.issuer = action.payload
        }
    },
    extraReducers: {
        [getIssuers.fulfilled]: (state, action) => {
            state.issuers = action.payload?.data
            state.currentPage = action.payload?.currentPage
            state.limit = action.payload?.limit
            state.pageCount = action.payload?.pageCount
            state.totalCount = action.payload?.totalCount
            state.isLoading = false
        },
        [getIssuers.pending]: (state) => {
            state.isLoading = true
        },
        [getIssuers.rejected]: (state, action) => {
            toast.error(action.payload)
            state.isLoading = false
        },

        [createIssuers.fulfilled]: (state, action) => {
            state.issuers.push(action.payload.data)
            state.isLoading = false
        },
        [createIssuers.pending]: (state) => {
            state.isLoading = true
        },
        [createIssuers.rejected]: (state, action) => {
            toast.error(action.payload)
            state.isLoading = false
        },

        [deleteIssuers.fulfilled]: (state, action) => {
            state.issuers = state.issuers.filter(item => item.id !== action?.meta?.arg)
            state.isLoading = false
        },
        [deleteIssuers.pending]: (state) => {
            state.isLoading = true
        },
        [deleteIssuers.rejected]: (state, action) => {
            toast.error(action.payload)
            state.isLoading = false
        },

        [patchIssuers.fulfilled]: (state, action) => {
            state.issuers[state.issuers.findIndex(item => item.id === action.payload?.data?.id)] = action.payload?.data
        },
        [patchIssuers.rejected]: (state, action) => {
            toast.error(action.payload)
        },

        [fetchIndexesFin.fulfilled]: () => {
           toast.success(i18n.t("success_fetch_indexes_fin"))
        },
        [fetchIndexesFin.rejected]: () => {
            toast.error(i18n.t("error_fetch_indexes_fin"))
        }
    }
})

export const {setIssuer} = issuerSlice.actions

export default issuerSlice.reducer